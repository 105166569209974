define('trackingList', ['app'], function(app) {
  
  let trackingList = function() {
    let self = this;
    self.app = app;

    let _paginationNum = document.querySelectorAll('.pagination_pageNumbers');
    self.config = {
      testClick: 'test-click'
    };

    /*Initialise tracking*/
    self.init = function(element) {
      self.element = element;
      self.dataLayer = window.dataLayer;
      self.check = 1;
      self.trackPage();
      app.subscribe('pageControls/update', function() {
        self.trackPage();
      });
      return self;
    };

    /*Track upper and lower pagination of the pages,
     *Its is done with two event listeners so the click
     *events can be restrickted to two specific parts of the
     *page, not the whole page. */
    self.trackPage = function() {
      if (self.check === 1) {
        document.querySelectorAll('[data-component="pageControls"]')[0].addEventListener('click', function(e) {
          self.app.publish(self.config.testClick);
          self.tracking(e);
        });
        if (_paginationNum.length > 1) {
          document.querySelectorAll('[data-component="pageControls"]')[1].addEventListener('click', function(e) {
            self.app.publish(self.config.testClick);
            self.tracking(e);
          });
        }
      }
      self.check++;
    };

    /*The actual tracking information
     * is retrieved from here using app.publish*/
    self.tracking = function(event) {
      self.event = event.target;
      if (self.event.hasAttribute('data-page-number')) {
        self.elementValue = self.event.getAttribute('data-page-number');
      }
      if (self.event.hasAttribute('data-direction')) {
        self.elementValue = self.event.dataset.direction;
      }
      app.publish('tracking/record', 'Pagination', 'Pagination Clicked', 'Page clicked: ' + self.elementValue, '\'' + window.location.href + '\'');
    };
  };
  return trackingList;
});
